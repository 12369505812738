import * as React from "react"
import "../styles/resets.css"
import { NavBar } from "../components/NavBar"

export default function Shop() {
  return (
    <div>
      <NavBar />
    </div>
  )
}
